<script>
import AdminList from "@/pages/admin/AdminList.vue";

export default {
  name: "indexAdmin",
  components: {AdminList}
}
</script>

<template>
<AdminList />
</template>

<style scoped>

</style>