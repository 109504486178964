<template>
    <div class="wrapper-becomeInvestor" id="becomeInvestor">
        <div class="containers">
            <h1 class="title">
                Стать инвестором за 7 простых шагов
            </h1>
            <div class="row">
                <div class="col-xl-8 dekstop_img">
                    <img src="../../../assets/img/lendingPages/BecomInvestorDekstop.svg" alt="">
                </div>
                <div class="col-xl-4 col-md-12">
                    <div class="cards">
                        <div class="card-item">
                            <img src="../../../assets/img/lendingPages/1Circle.svg" alt="">
                            <div class="card-item-text">
                                Зарегистрируйтесь
                            </div>
                        </div>
                        <div class="card-item">
                            <img src="../../../assets/img/lendingPages/2Circle.svg" alt="">
                            <div class="card-item-text">
                                Верифицируйте свой аккаунт
                            </div>
                        </div>
                        <div class="card-item">
                            <img src="../../../assets/img/lendingPages/3Circle.svg" alt="">
                            <div class="card-item-text">
                                Выберите проекты
                            </div>
                        </div>
                        <div class="card-item">
                            <img src="../../../assets/img/lendingPages/4Circle.svg" alt="">
                            <div class="card-item-text">
                                Пополните свой счет 
                            </div>
                        </div>
                        <div class="card-item">
                            <img src="../../../assets/img/lendingPages/5Circle.svg" alt="">
                            <div class="card-item-text">
                                Инвестируйте в проекты 
                            </div>
                        </div>
                        <div class="card-item">
                            <img src="../../../assets/img/lendingPages/6Circle.svg" alt="">
                            <div class="card-item-text">
                                Следите за успехами проектов 
                            </div>
                        </div>
                        <div class="card-item">
                            <img src="../../../assets/img/lendingPages/7Circle.svg" alt="">
                            <div class="card-item-text">
                                Получайте доход
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>