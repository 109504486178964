<template>
<div class="wrapper-goalsPlatform">
  <div class="container">
    <div class="title mb-5">
      Цели платформы
    </div>
    <div class="row">
      <div class="col-xl-6">
        <div class="card-item one-card-item">
          <img class="img-mobile" src="../../../assets/img/lendingPages/OneIcon.svg" alt="" height="50px" width="50px" />
          <div class="title">
            Соединить 1120 городов России в единую высокотехнологичную сеть
          </div>
          <div class="description">
            В контексте инновационно-проектной деятельности для синергии ресурсов и устойчивого развития
            (с перспективой масштабирования на дружественные города стран СНГ, БРИКС, ШОС, ЕАЭС).
          </div>
        </div>
      </div>
      <div class="col-xl-6 d-flex justify-content-between">
            <img class="img-desktop" src="../../../assets/img/lendingPages/OneIcon.svg" alt="" height="50px" width="50px" />
            <div></div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-xl-6">
      </div>
      <div class="col-xl-6 d-flex justify-content-between align-items-md-start">
        <img class="img-desktop" src="../../../assets/img/lendingPages/TwoIcon.svg" alt="">
        <div class="card-item two-card-item">
          <img class="img-mobile" src="../../../assets/img/lendingPages/TwoIcon.svg" alt="">
          <div class="title">
            Продвигать тренд финансово здорового образа жизни
          </div>
          <div class="description">
            На основе новой финансовой культуры,
            чтобы люди осознали все возможности и перспективы грамотного обращения с деньгами и начали их использовать.
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-xl-6">
        <div class="card-item one-card-item">
          <img class="img-mobile" src="../../../assets/img/lendingPages/ThreeIcon.svg" alt="">
          <div class="title">
            Обучить не менее 1 млн. пользователей платформы финансовой грамотности
          </div>
          <div class="description">
            И дать им возможность сразу же применить полученные знания на практике с помощью удобных цифровых
            инструментов для достижения конкретных финансовых целей.
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class=" d-flex justify-content-between">
          <img class="img-desktop" src="../../../assets/img/lendingPages/ThreeIcon.svg" alt="">
          <div></div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "GoalsPlatform"
}
</script>


<style scoped>

</style>