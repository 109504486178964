<script>
export default {
  name: "header"
}
</script>

<template>
<div class="wrapper-HeaderInvestment d-flex  justify-content-between align-items-center">
  <div class="menu-Logo">
          <img src="../../../assets/img/lendingPages/logoLendingPage.svg" alt="">
    <div class="menu-logo-text">
      Развиваем города
    </div>
  </div>
  <div class="menu-item-main d-flex justify-content-around">
    <div class="menu-item">Инвестировать</div>
    <div class="menu-item">Выдать займ</div>
    <div class="menu-item">Спонсировать</div>
    <div class="menu-item">БлагоТворить</div>
    <div class="menu-item">Голосовать</div>
    <div class="menu-item">Инвестиционный портфель</div>
    <div class="menu-item">Стратегия</div>
    <div class="menu-item">Рассчитать доход</div>
  </div>
  <div class="menu-btn-avatar d-flex">
    <div class="menu-btn button button-primary">Создать проект</div>
    <img src="../../../assets/img/investmentPages/nonAvatarInvestment.svg" alt="">
  </div>
</div>
</template>

<style scoped>

</style>