<template>
  <div>
    Верификация

    <div class="button button-primary">
      send
    </div>
  </div>
</template>

<script>

export default {
  name: "verification",

  data(){
    return{

    }
  }
}

</script>



